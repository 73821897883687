<template>
  <div
    class="ib-card-selectable d-flex flex-column text-center"
    :class="classes"
    @click="$emit('click', value)"
  >
    <div class="image" :style="{backgroundImage: `url(${img})`}" />

    <p class="font-outfit-regular card-title">
      {{ title }}
    </p>
    <p class="font-outfit-light card-description ma-0">
      {{ description }}
    </p>
    <div v-if="showUpgrade" class="tag-upgrade font-outfit-regular">
      <i class="ib-icon ib-icon-rocket upgrade-icon" />
      {{ $t('upgrade') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'IbCardSelectable',

  props: {
    img: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: null
    },
    value: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    showUpgrade: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return {
        selected: this.active
      }
    }
  }
}

</script>

<style scoped lang="scss">
.ib-card-selectable {
  position: relative;
  border: 2px solid #D9D9D9;
  background: $color-white;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.25s;
  padding: 20px;
  flex-grow: 1;

  @include media-breakpoint-up($md) {
    opacity: .7;
    align-self: stretch;
  }

  .card-title {
    font-size: 16px;
    margin: 0 0 9px;
  }

  .card-description {
    color: $color-text-gray;
    font-size: 14px;
  }

  .image {
    width: 100%;
    margin-bottom: 25px;
    min-height: 58px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
    transition: all 0.25s;
    opacity: .7;
    filter: grayscale(100%);
  }

  &.selected {
    border: 2px solid $color-primary;
    opacity: 1;

    .card-title {
      font-family: outfit-medium, serif;
    }

    .card-description {
      color: $color-navy;
    }

    .image {
      filter: grayscale(0%);
    }
  }

  &:hover {
    opacity: 1;

    .card-title {
      font-family: outfit-medium, serif;
    }

    .card-description {
      color: $color-navy;
    }

    .image {
      filter: grayscale(0%);
    }
  }

  .tag-upgrade {
    position: absolute;
    right: 0;
    background: $color-yellow;
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    font-size: 13px;
    padding: 0 7px;
    height: 21px;

    .upgrade-icon {
      color: $color-white;
      font-size: 16px;
      margin-right: 3px;
    }
  }
}
</style>
